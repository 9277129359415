const TERMS_INDEX = {
  label: 'TERMS INDEX',
  path: '/terms',
  isInternal: true
}

const TERMS_OF_SERVICE = {
  label: 'TERMS OF SERVICE',
  path: '/terms/terms-of-service',
  isInternal: true
}

const PRIVACY_NOTICE = {
  label: 'PRIVACY NOTICE',
  path: 'https://newfold.com/privacy-center',
  isInternal: false
}

const ACCEPTABLE_USE = {
  label: 'ACCEPTABLE USE',
  path: '/terms/acceptable-use',
  isInternal: true
}

const TRADEMARK_DISPUTES = {
  label: 'TRADEMARK DISPUTES',
  path: '/terms/trademark-disputes',
  isInternal: true
}

const DOMAIN_REGISTRATION = {
  label: 'DOMAIN REGISTRATION',
  path: '/terms/domain-registration-agreement',
  isInternal: true
}

const COPYRIGHT = {
  label: 'COPYRIGHT',
  path: '/terms/copyright-infringement-policy',
  isInternal: true
}

const DNRN_POLICY = {
  label: 'DNRN POLICY',
  path: '/terms/domain-renewal-notification-policy',
  isInternal: true
}

const DATA_REQUEST_POLICY = {
  label: 'DATA REQUEST POLICY',
  path: '/terms/data-request-policy',
  isInternal: true
}

const ARBITRATION_AGREEMENT = {
  label: 'ARBITRATION AGREEMENT',
  path: '/terms/arbitration-agreement',
  isInternal: true
}
const PROFESSIONAL_SERVICES_AGREEMENT = {
  label: 'PROFESSIONAL SERVICES AGREEMENT',
  path: '/terms/professional-services-agreement',
  isInternal: true
}

export const ALL_LINKS = [
  TERMS_INDEX,
  TERMS_OF_SERVICE,
  PRIVACY_NOTICE,
  ACCEPTABLE_USE,
  TRADEMARK_DISPUTES,
  DOMAIN_REGISTRATION,
  COPYRIGHT,
  DNRN_POLICY,
  DATA_REQUEST_POLICY,
  ARBITRATION_AGREEMENT,
  PROFESSIONAL_SERVICES_AGREEMENT
]
