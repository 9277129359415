import styled, { css } from 'styled-components'
import { grid, space } from 'styled-system'
import { Link } from 'gatsby'

const activeLink = css`
  color: #333;
  pointer-events: none;
  cursor: unset;
`

const Grid = styled.div`
  display: grid;
  font-size: 20px;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, minmax(100px, 1fr));

  @media (max-width: 775px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
  @media (max-width: 324px) {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }

  ${grid}
  ${space}
`

Grid.Link = styled(Link)`
  font-size: 0.65em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #2196f3;
  text-decoration: none;
  ${({ isActive }) => isActive && activeLink}
`

Grid.defaultProps = {
  gridGap: 4
}

Grid.externalLink = styled.a`
  font-size: 0.65em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #2196f3;
  text-decoration: none;
`

export default Grid
