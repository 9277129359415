import React from 'react'

import { ALL_LINKS } from './link-grid.constants'

import Grid from './link-grid.styles'

const LinkGrid = props => (
  <Grid {...props}>
    {ALL_LINKS.map(({ label, path, isInternal }) => {
      if (isInternal) {
        const pathRegex = new RegExp(path, 'i')
        return (
          <Grid.Link
            key={`grid-${label}`}
            to={path}
            isActive={
              typeof window !== `undefined` &&
              path !== '/terms' &&
              pathRegex.test(window.location.pathname)
            }
          >
            {label}
          </Grid.Link>
        )
      } else {
        return <Grid.externalLink href={path}> {label}</Grid.externalLink>
      }
    })}
  </Grid>
)

export default LinkGrid
