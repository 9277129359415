import React from 'react'
import styled from 'styled-components'

import GridLink from '../link-grid'

const TermsContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`

const TermsLayout = ({ children }) => (
  <TermsContainer>
    <GridLink py={4} />
    {children}
  </TermsContainer>
)

export default TermsLayout
