import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { Link } from 'gatsby'

import Typography from '@eig-builder/core-ui/Typography'

import { withModifiers } from '../../helpers/withModifiers'

const counterContainer = css`
  ${props =>
    props.counterSection &&
    `
      counter-reset: ${props.counterSection};
    `}
`

const counter = css`
  ${({ counter: pCounter, counterType = 'decimal' }) =>
    pCounter &&
    `
      &::before {
        counter-increment: ${pCounter};
        content: counter(${pCounter}, ${counterType}) ". ";
      }
    `}
`

const Acceptable = styled.section.attrs(props => ({ className: withModifiers(props) }))`
  ${space}
  ${counterContainer}

  &._borderBottom {
    padding-top: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.black.lighter};
  }
`

Acceptable.subSection = styled.div`
  ${space}
  ${counterContainer}
`

Acceptable.Header = styled(Typography).attrs(() => ({ variant: 'h1' }))({}, space)

Acceptable.SubHeader = styled(Typography).attrs(() => ({ variant: 'h2' }))`
  ${counter}
  ${space}
`

Acceptable.Text = styled(Typography).attrs(() => ({ variant: 'body1', component: 'span' }))``

Acceptable.basicText = styled.span.attrs(props => ({ className: withModifiers(props) }))`
  &._bold {
    font-weight: bold;
    line-height: 1.42857;
  }
`

Acceptable.uList = styled.ul.attrs(({ listType = 'upper-alpha' }) => ({ listType }))`
  list-style: ${({ listType }) => listType}};
`

Acceptable.listItem = styled.li`
  padding: 8px 0;
`

Acceptable.Link = styled(Link)``

Acceptable.anchor = styled.span`
  display: block;
  height: 82px;
  margin-top: -82px;
  visibility: hidden;
`

export default Acceptable
