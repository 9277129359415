import React from 'react'

import Acceptable from './acceptable-use.styles'

const AcceptableUse = () => (
  <>
    {/* Sitebuilder Acceptable Use: Section 1 */}
    <Acceptable _borderBottom>
      <Acceptable.subSection pt={5}>
        <Acceptable.Header pb={4}>Sitebuilder Acceptable Use</Acceptable.Header>
        <Acceptable.Text>
          This Acceptable Use Policy ("AUP") governs your use of the Services
          and is incorporated by reference into Sitebuilder’s
          <Acceptable.Link to='/terms/terms-of-service'>{` Terms of Services`}</Acceptable.Link>
          . Unless otherwise stated, defined terms in this AUP have the meanings
          provided to them in the Terms of Services. Sitebuilder may modify the
          AUP at any time without notice.
          <br />
          <br />
          You shall use the Services only for lawful purposes. Transmission,
          storage, or display of any information, data, or material in violation
          of applicable laws or regulations, including without limitation the
          laws of the Commonwealth of Massachusetts, is prohibited. Sitebuilder
          reserves the right to terminate the Services for any Customer that
          exposes Sitebuilder to legal liability or threatens its ability to
          provide services to other customers. You agree to indemnify and hold
          Sitebuilder harmless from any claims resulting from your use of the
          Services.
        </Acceptable.Text>
      </Acceptable.subSection>
      <Acceptable.subSection pt={5}>
        <Acceptable.SubHeader>QUICK LINKS</Acceptable.SubHeader>
        <Acceptable.Text>
          <p>
            <a href='#PROHIBITEDUSES'>Prohibted Uses</a>
          </p>
          <p>
            You may not use the Services to publish content or engage in
            activity that is harmful to others or illegal under applicable law.
            Any content that, in our judgment, violates our Terms of Service,
            including this AUP, in any manner may be removed from our servers
            (or otherwise disabled), with or without notice. Examples of
            prohibited content and activities can be found in this section.
          </p>
          <p className='lead'>
            <a href='#ZEROTOLERANCESP'>Zero Tolerance Spam Policy</a>
          </p>
          <p>
            Sitebuilder maintains a zero tolerance policy for use of its network
            or services to send unsolicited bulk or commercial e-mail, or the
            sending, assisting, or commissioning the transmission of commercial
            e-mail that does not comply with the U.S. CAN-SPAM Act of 2003
            ("SPAM"). Please review this section to avoid action under our AUP.
          </p>
          <p className='lead'>
            <a href='#DEFAMATIONANDOB'>Defamation and Objectionable Content</a>
          </p>
          <p>
            Sitebuilder respects that the Internet provides a forum for free and
            open discussion and dissemination of information. Accordingly, we
            generally do not screen, monitor or control customer content.
            However, if we are made aware of content that violates the law or
            this AUP, we will consider the matter and take appropriate action as
            determined in our sole discretion balancing the merits of the
            complaint with the right to freedom of expression.
          </p>
          <p className='lead'>
            <a href='#ENFORCEMENT'>Enforcement</a>
          </p>
          <p>
            Sitebuilder may terminate your Services with or without notice upon
            any violation of this AUP. This section describes how Sitebuilder
            may enforce this AUP.
          </p>
          <p className='lead'>
            <a href='#REPORTINGVIOLAT'>Reporting AUP Violations</a>
          </p>
          <p>
            If you feel you have discovered a violation of our AUP, please
            review this section to learn about our reporting procedures.
          </p>
        </Acceptable.Text>
      </Acceptable.subSection>
    </Acceptable>
    {/* Sitebuilder Acceptable Use: Section 2 */}
    <Acceptable counterSection='acceptable-use-counter'>
      <Acceptable.Header>Sitebuilder Acceptable Use</Acceptable.Header>
      <Acceptable.subSection pt={5}>
        <Acceptable.anchor id='PROHIBITEDUSES' />
        <Acceptable.SubHeader counter='acceptable-use-counter'>
          Prohibited Uses
        </Acceptable.SubHeader>
        <Acceptable.Text>
          <Acceptable.uList>
            <Acceptable.listItem>
              <Acceptable.basicText _bold>
                No Illegal or Harmful Use
              </Acceptable.basicText>{' '}
              - You may not use the Services to publish content or engage in
              activity that is harmful to others or illegal under applicable
              law, including without limitation in connection with any of the
              following illegal, harmful or fraudulent activities:
            </Acceptable.listItem>
            <Acceptable.uList listType='lower-roman'>
              <Acceptable.listItem>
                Disclosing private sensitive personal information about others;
              </Acceptable.listItem>
              <Acceptable.listItem>
                Distributing malware or other malicious code;
              </Acceptable.listItem>
              <Acceptable.listItem>
                Engaging in the unlawful distribution of controlled substances
                and drug contraband;
              </Acceptable.listItem>
              <Acceptable.listItem>
                Engaging in the unlawful distribution of prescription
                medications, including, but not limited to, promotion,
                marketing, or sale of prescription medications without a valid
                prescription;
              </Acceptable.listItem>
              <Acceptable.listItem>Gambling;</Acceptable.listItem>
              <Acceptable.listItem>
              Hosting, distributing or linking to child sexual abuse material 
              (CSAM) or content that is harmful to minors (CSAM will be suspended 
              immediately without notice and reported to law enforcement or the 
              National Center for Missing and Exploited Children);
              </Acceptable.listItem>
              <Acceptable.listItem id='viiHostingFOREX'>
                Hosting FOREX, E-Gold Exchange, Second Life/Linden Exchange,
                Ponzi, MLM/Pyramid Scheme, High-Yield Interest Programs (HYIP)
                or related sites;
              </Acceptable.listItem>
              <Acceptable.listItem id='viiiHostingorli'>
                Hosting or linking to a website intended to deceive the public
                including, but not limited to sites listed at{' '}
                <a href='http://aa419.org/'>aa419.org</a> &amp;{' '}
                <a href='http://escrow-fraud.com/'>escrow-fraud.com</a>;
              </Acceptable.listItem>
              <Acceptable.listItem id='ixHostingorlink'>
                Hosting or linking to an anonymous proxy server;
                <br />
              </Acceptable.listItem>
              <Acceptable.listItem id='xInfringingupon'>
                Infringing upon the Intellectual Property Rights of Others.This
                includes, but is not limited to, the unauthorized copying or
                distribution of movies, music, books, photographs,
                software/warez, or any other copyrighted work. If you believe
                that your intellectual property rights are being infringed upon,
                please email us at{' '}
                <a href='mailto:abuse@Sitebuilder.com'>abuse@Sitebuilder.com</a>
                ;<br />
              </Acceptable.listItem>
              <Acceptable.listItem id='xiMoneylaunderi'>
                Money laundering;
              </Acceptable.listItem>
              <Acceptable.listItem id='xiiPhishingoren'>
                Phishing or engaging in identity theft;
              </Acceptable.listItem>
              <Acceptable.listItem id='xiiiSellingweap'>
                Selling weapons or ammunition;
              </Acceptable.listItem>
            </Acceptable.uList>
            <Acceptable.listItem id='bNoUnauthorized'>
              <Acceptable.basicText _bold>
                No Unauthorized System Access or Network Abuse
              </Acceptable.basicText>{' '}
              - You may not use the Services to gain access into any network or
              system without permission. Prohibited activities include:
            </Acceptable.listItem>
            <Acceptable.uList listType='lower-roman'>
              <Acceptable.listItem id='iAccessinganoth'>
                Accessing another network without permission, to probe or scan
                for vulnerabilities or breach security or authentication
                measures;
              </Acceptable.listItem>
              <Acceptable.listItem id='iiAttackingothe'>
                Attacking other networks (i.e. Denial of Service (DoS) attacks);
              </Acceptable.listItem>
              <Acceptable.listItem id='iiiIntercepting'>
                Intercepting or monitoring data without permission;
              </Acceptable.listItem>
              <Acceptable.listItem id='ivRunningafiles'>
                Running a file sharing site;
              </Acceptable.listItem>
              <Acceptable.listItem id='vRunninganysoft'>
                Running any software that interfaces with an IRC (Internet Relay
                Chat) network;
              </Acceptable.listItem>
              <Acceptable.listItem id='viUsinganydeepl'>
                Using any deep-link, page-scrape, robot, crawl, index, spider,
                offline reader, click spam, macro programs, internet agent, or
                other automatic device, program, algorithm or methodology which
                does the same things, to use, access, copy, index, acquire
                information, generate impressions or clicks, input information,
                store information, search, generate searches, or monitor any
                portion of the Sitebuilder’s website or servers for any
                unauthorized purpose;
              </Acceptable.listItem>
            </Acceptable.uList>
            <Acceptable.listItem id='cDontHamperSyst'>
              <Acceptable.basicText _bold>
                Don't Hamper System Performance
              </Acceptable.basicText>{' '}
              - Consuming excessive amount of server resources is prohibited.
              This leads to server performance issues and may cause a disruption
              to our systems or other customers. Use of any scripts or processes
              that may adversely impact our systems is prohibited.
              <br />
            </Acceptable.listItem>
            <Acceptable.listItem id='dNoAdultContent'>
              <Acceptable.basicText _bold>
                No Adult Content
              </Acceptable.basicText>
              - You may not use the Services to distribute pornography or other
              adult-related content or offer any escort services.
            </Acceptable.listItem>
            <Acceptable.listItem id='eNoStorageofBac'>
              <Acceptable.basicText _bold>
                No Storage of Backups
              </Acceptable.basicText>{' '}
              - Backing up personal data to a hosting account is prohibited. Our
              Services are designed to host your web site, not serve as a data
              repository. Sitebuilder reserves the right to remove backups from
              your hosting account.
            </Acceptable.listItem>
          </Acceptable.uList>
        </Acceptable.Text>
      </Acceptable.subSection>
      <Acceptable.subSection pt={1}>
        <Acceptable.anchor id='ZEROTOLERANCESP' />
        <Acceptable.SubHeader counter='acceptable-use-counter'>
          Zero Tolerance Spam Policy
        </Acceptable.SubHeader>
        <Acceptable.uList>
          <Acceptable.listItem>
            You may not use the Services to send spam or bulk unsolicited
            messages. Sitebuilder maintains a zero tolerance policy for use of
            its network or services in any manner associated with the
            transmission, distribution or delivery of any bulk e-mail, including
            unsolicited bulk or unsolicited commercial e-mail, or the sending,
            assisting, or commissioning the transmission of commercial e-mail
            that does not comply with the U.S. CAN-SPAM Act of 2003 ("SPAM").
          </Acceptable.listItem>
          <Acceptable.listItem>
            "Safe lists," purchased lists, and selling of lists will be treated
            as SPAM. We may terminate the account of any User who sends out SPAM
            with or without notice.
          </Acceptable.listItem>
          <Acceptable.listItem>
            Websites advertised via SPAM (i.e. Spamvertised) may not be hosted
            on our servers. This provision includes, but is not limited to, SPAM
            sent via fax, phone, postal mail, email, instant messaging, or
            usenet/newsgroups. No organization or entity listed in the
            <a href='http://www.spamhaus.org/rokso/'>{` ROKSO `}</a>may use our
            Services. Any User account which results in our IP space being
            blacklisted will be immediately suspended and/or terminated.
          </Acceptable.listItem>
          <Acceptable.listItem>
            Please familiarize yourself with the CAN-SPAM Act. Information about
            the CAN-SPAM Act may be found by accessing the following link:{' '}
            <a href='https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business'>
              https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business
            </a>
          </Acceptable.listItem>
        </Acceptable.uList>
      </Acceptable.subSection>
      <Acceptable.subSection pt={1} pb={3}>
        <Acceptable.anchor id='DEFAMATIONANDOB' />
        <Acceptable.SubHeader counter='acceptable-use-counter' pb={3}>
          Defamation and Objectionable Content
        </Acceptable.SubHeader>
        <Acceptable.Text>
          Sitebuilder values the freedom of expression and encourages Users to
          be respectful with the content they post. As a webhost, Sitebuilder is
          not a publisher of User content and is generally not in a position to
          investigate the veracity of individual defamation claims or to
          determine whether certain material, which Sitebuilder may find
          objectionable, should be censored. Accordingly, Sitebuilder generally
          requires a court order from a court of competent jurisdiction, as
          determined by Sitebuilder in its sole discretion, to take down alleged
          defamatory or objectionable content. However, Sitebuilder reserves the
          right to disable or remove any content to prevent harm to others or to
          Sitebuilder, as determined in Sitebuilder's sole discretion.
        </Acceptable.Text>
      </Acceptable.subSection>
      <Acceptable.subSection pt={2}>
        <Acceptable.anchor id='ENFORCEMENT' />
        <Acceptable.SubHeader counter='acceptable-use-counter'>
          Enforcement
        </Acceptable.SubHeader>
        <Acceptable.uList>
          <Acceptable.listItem>
            <Acceptable.basicText _bold>Resellers.</Acceptable.basicText>
          </Acceptable.listItem>
          <Acceptable.uList listType='lower-roman'>
            <Acceptable.listItem>
              If there is a violation of this AUP by a User of a Reseller,
              Sitebuilder will suspend the account in question and notify the
              Reseller so that the Reseller can address the matter with the
              User. The occurrence of additional violations on a User account
              may result in the immediate suspensions or termination of your
              Reseller account.
            </Acceptable.listItem>
          </Acceptable.uList>
          <Acceptable.listItem>
            <Acceptable.basicText _bold>Direct Customers.</Acceptable.basicText>
          </Acceptable.listItem>
          <Acceptable.uList listType='lower-roman'>
            <Acceptable.listItem>
              Your Services may be terminated with or without notice upon any
              violation of this AUP.
            </Acceptable.listItem>
          </Acceptable.uList>
          <Acceptable.listItem>
            If applicable, violations will be reported to the appropriate law
            enforcement agency.
          </Acceptable.listItem>
          <Acceptable.listItem>
            A failure to respond to an email from our compliance team within
            forty-eight (48) hours, or as otherwise specified in the email, may
            result in the suspension or termination of your Services.
          </Acceptable.listItem>
          <Acceptable.listItem>
            We reserve the right to enforce, or not enforce, this AUP in our
            sole discretion.
          </Acceptable.listItem>
        </Acceptable.uList>
      </Acceptable.subSection>
      <Acceptable.subSection py={2}>
        <Acceptable.anchor id='REPORTINGVIOLAT' />
        <Acceptable.SubHeader counter='acceptable-use-counter' pb={3}>
          Reporting Violations of this AUP
        </Acceptable.SubHeader>
        <Acceptable.Text>
          If you feel you have discovered a violation of our AUP please email us
          at
          <a href='mailto:abuse@Sitebuilder.com'>{` abuse@Sitebuilder.com`}</a>.
          We strive to make the Web a better place and consider the abuse of our
          services a serious matter. We investigate and record all reports of
          abuse submitted to us. Where appropriate, we may take action if a
          violation of any of our terms of service is confirmed or when required
          to do so if an activity violates the policies of ICANN or appropriate
          registry. We may request additional information from you about your
          report. However, please note we may not be able to provide you with
          specific updates in response to your specific report due to privacy
          reasons.
        </Acceptable.Text>
      </Acceptable.subSection>
    </Acceptable>
  </>
)

export default AcceptableUse
